import React from 'react';
import PropTypes from 'prop-types';
import { overScrollColors } from '../../util/scrollTrack';
import useSiteMetaHook from '../../hooks/useSiteMetaHook';
import '../../util/fontAwesome';
import Header from '../Header/Header';
import * as styles from './LayoutHome.module.scss';

const LayoutHome = ({ pageTitle, children }) => {
  const { title } = useSiteMetaHook();
  overScrollColors('#FFC803', '#353535');
  return (
    <div className={[styles.container, styles.hasHero].join(' ')}>
      <Header className={styles.header} title={title} transparentRoutes={['/']} />
      <div
        className={styles.content}
      >
        <main>
          {pageTitle && <h1 className={styles.pageTitle}>{pageTitle}</h1>}
          {children}
        </main>
      </div>
    </div>
  );
};

LayoutHome.propTypes = {
  pageTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
};

LayoutHome.defaultProps = {
  pageTitle: null,
};

export default LayoutHome;
