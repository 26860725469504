import React from 'react';
import PropTypes from 'prop-types';


const WaveSectionPlaceholder = ({ children }) => <div>{children}</div>;


WaveSectionPlaceholder.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  onAction: PropTypes.func,
  actionComponent: PropTypes.element,
  hasWave: PropTypes.bool,
  mergeTop: PropTypes.bool,
  waveBackgroundColor: PropTypes.oneOf(['white', 'lightestGrey', 'darkGrey', 'darkestGrey']),
  backgroundColor: PropTypes.oneOf(['white', 'lightestGrey', 'darkGrey', 'darkestGrey']),
  waveCurve: PropTypes.shape({
    start: PropTypes.string,
    middle: PropTypes.string,
    end: PropTypes.string,
  })
};

WaveSectionPlaceholder.defaultProps = {
  id: null,
  title: null,
  waveCurve: null,
  onAction: null,
  actionComponent: null,
  hasWave: true,
  mergeTop: false,
  waveBackgroundColor: 'darkGrey',
  backgroundColor: 'lightestGrey',
};

export default WaveSectionPlaceholder;
