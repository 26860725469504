import React from 'react';
import WaveSection from './WaveSection';

const WaveSectionGenerator = ({ children }) => (
  <div>
    {children.map((child, index) => (
      <WaveSection
        key={child.props.title}
        id={child.props.id}
        title={child.props.title}
        subtitle={child.props.subtitle}
        waveBackgroundColor={index > 0 ? children[index - 1].props.backgroundColor : null}
        backgroundColor={child.props.backgroundColor}
        hasWave={child.props.mergeTop || child.props.hasWave || index !== 0}
        mergeTop={child.props.mergeTop}
        actionComponent={child.props.actionComponent}
        waveCurve={child.props.waveCurve}
      >
        {child}
      </WaveSection>
    ))}
  </div>
);

export default WaveSectionGenerator;
