import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LayoutHome from '../components/LayoutHome/LayoutHome';
import Visualisation from '../components/Visualisation/Visualisation';
import WaveSectionGenerator from '../components/WaveSection/WaveSectionGenerator';
import WaveSectionPlaceholder from '../components/WaveSection/WaveSectionPlaceholder';
// import FAB from '../components/FAB/FAB';
import Button from '../components/Button/Button';
// import ProjectRow from '../components/ProjectRow/ProjectRow';
// import BlogRow from '../components/BlogRow/BlogRow';
import Contact from '../forms/Contact';
import Footer from '../components/Footer/Footer';
import SEO from '../components/seo';

const IndexPage = () => {
  const waveSVGShapes = [
    {
      start: 'M0,300L0,100C263.715,96.283 500,150 500,150C500,150 733.503,204.972 1000,200L1000,300L0,300Z',
      middle: null,
      end: 'M0,300L0,200C263.715,196.283 500,150 500,150C500,150 733.503,104.972 1000,100L1000,300L0,300Z',
    },
    {
      start: 'M0,300L0,275C263.715,271.283 500,150 500,150C500,150 733.503,29.972 1000,25L1000,300L0,300Z',
      middle: null,
      end: 'M0,300L0,25C263.715,21.283 500,150 500,150C500,150 733.503,279.972 1000,275L1000,300L0,300Z',
    },
    {
      start: 'M0,300L0,200C179.139,246.426 526.508,115.57 736.518,150C925.54,180.989 885.969,200.082 1000,200L1000,300L0,300Z',
      middle: null,
      end: 'M0,300L0,200C0,200 151.589,153.97 299.004,150C488.028,144.909 510.159,239.004 1000,200L1000,300L0,300Z',
    },
    {
      start: 'M0,300L0,175C0,175 34.603,197.777 178.176,188.183C292.806,180.522 313.395,144.79 522.444,136.083C761.431,126.13 1000,225 1000,225L1000,300L0,300Z',
      middle: null,
      end: 'M0,300L0,150C0,150 45.473,157.221 227.751,150C398.551,143.234 581.07,96.724 837.08,135.832C955.57,153.932 1000,225 1000,225L1000,300L0,300Z',
    },
    {
      start: 'M0,300L0,125C0,125 123.922,163.342 354.703,150C585.484,136.658 703.57,249.946 1000,200L1000,300L0,300Z',
      middle: null,
      end: 'M0,300L0,250C0,250 188.656,266.008 419.438,252.666C650.219,239.324 703.57,167.256 1000,117.31L1000,300L0,300Z',
    },
  ];

  return (
    <LayoutHome>
      <SEO title="Home" keywords={['test', 'test']} />
      <Visualisation />
      <WaveSectionGenerator>
        
        <WaveSectionPlaceholder
          mergeTop
          title="Who are we?"
          backgroundColor="white"
          waveCurve={waveSVGShapes[0]}
        >
          <p>
            Flowmoco are a no-nonsense team of 30 highly skilled and professional programmers, data engineers and technical architects that strive for excellence in every commission. We fold in the best bits of a diverse plethora of platforms, languages and paradigms that we have learned and loved through our 500+ years of collective experience.
          </p>
          <p>
            This adaptability underpins our strength of delivery and means we can build and deploy (necessarily) complex systems into a variety of arenas ranging from air-gapped PCI compliant Kubernetes environments for finance to managed private clouds for global Telco operators.
          </p>
        </WaveSectionPlaceholder>

        {/* <WaveSectionPlaceholder
          id="featured-projects"
          mergeTop
          title="Featured Projects"
          backgroundColor="white"
          actionComponent={<FAB to="#featured-projects" icon={<FontAwesomeIcon icon="chevron-down" />} />}
          waveCurve={waveSVGShapes[0]}
        >
          <br />
          <ProjectRow />
        </WaveSectionPlaceholder> */}

        <WaveSectionPlaceholder
          title="What do we do?"
          backgroundColor="lightestGrey"
          waveCurve={waveSVGShapes[1]}
        >
          <p>
          Collectively we’ve got over 500 years of experience writing software - and we are still driven by the satisfaction of turning a tricky problem into a neat, well crafted solution that ultimately makes our business stakeholders smile.
          </p>
          <p>
            We might be technologists - our output may be primarily digital - but the problems we solve are often human.
          </p>
          <ul style={{
            textAlign: 'left'
          }}>
            <li>We build web and mobile applications that talk to data-rich back end systems</li>
            <li>We build data-rich back ends that are integrated with diverse business systems</li>
            <li>We build business systems that process huge, complex datasets to deliver insight</li>
            <li>We architect and manage secure environments, for your code, or ours.</li>
          </ul>
        </WaveSectionPlaceholder>

        {/* <WaveSectionPlaceholder
          title="Our Principles Yield Results"
          backgroundColor="lightestGrey"
          actionComponent={(
            <Button
              icon={<FontAwesomeIcon icon="th" />}
              label="More Projects"
              to="/portfolio"
            />
          )}
          waveCurve={waveSVGShapes[1]}
        >
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce et rhoncus libero. Ut porttitor pharetra nisi, ac molestie lacus eleifend sed. Donec convallis lorem vel dolor placerat fermentum.</p>
          <BlogRow />
          <br />
          <Button label="More" to="/blog" />
        </WaveSectionPlaceholder> */}

        <WaveSectionPlaceholder
          title="Who do we work with?"
          backgroundColor="white"
          waveCurve={waveSVGShapes[2]}
        >
          <ul style={{
            textAlign: 'left'
          }}>
            <li>Existing IT teams looking to extend their capability</li>
            <li>Design Agencies that requires specialist technologists to realise their creative vision</li>
            <li>Businesses of all sizes that require end-to-end project management and delivery</li>
          </ul>
          {/* <br /> */}
          {/* <Button label="Contact Us" to="/contact" /> */}
        </WaveSectionPlaceholder>
       
        {/* <WaveSectionPlaceholder
          title="Who we work with"
          backgroundColor="lightestGrey"
          waveCurve={waveSVGShapes[0]}
        >
          <p>We work with marketing and design agencies who need an expert on-side, enterprise clients who want a nimble, responsive and agile team, and for small businesses who are the knowledgeable partner that can deliver real value.</p>
          <br />
          <Button label="About Us" to="/careers" />
        </WaveSectionPlaceholder> */}

        <WaveSectionPlaceholder
          id="contact"
          title="Get in touch"
          backgroundColor="darkGrey"
          waveCurve={waveSVGShapes[1]}
        >
          <Contact light />
        </WaveSectionPlaceholder>
      </WaveSectionGenerator>
      <Footer waveBackgroundColor="darkGrey" />
    </LayoutHome>
  );
};

export default IndexPage;
