import React from 'react';
import p5 from 'p5';
import Button from '../Button/Button';
import * as styles from './Visualisation.module.scss';

export default class Visualisation extends React.Component {
  constructor(props) {
    super(props);
    this.sketch = this.sketch.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.state = { menuOpen: false };
  }

  componentDidMount() {
    this.canvas = new p5(this.sketch, this.wrapper);
  }

  sketch(p) {
    const dotCount = 4;
    let rings = [];
    let dashes = [];

    let xspacing = 32; // Distance between each horizontal location
    let w; // Width of entire wave
    let theta = 0.0; // Start angle at 0
    // let amplitude = 200.0; // Height of wave
    let period = 1000.0; // How many pixels before the wave repeats
    let dx; // Value for incrementing x
    // let yValues; // Using an array to store height values for the wave


    p.setup = () => {
      p.createCanvas(window.innerWidth, window.innerHeight < 1000 ? window.innerHeight : 1000);
      p.colorMode(p.HSB);
      p.ringsSet();
      p.dashesSet();
      p.noStroke();

      period = 1000 * 2;
      w = 1500 * 2;
      dx = (p.TWO_PI / period) * xspacing;
      // yValues = new Array(p.floor(w / xspacing));
    };

    window.onresize = (e) => {
      p.resizeCanvas(window.innerWidth, window.innerHeight < 1000 ? window.innerHeight : 1000);
    };

    p.draw = () => {
      p.clear();

      // p.calcWave();
      p.renderWave(p.calcWave(200), -300, [156, 43, 69]);

      // Rings
      rings.forEach(dinst => {
        p.stroke(56, 85, 97, 1);
        p.strokeWeight(6);
        p.fill(0, 0, 0, 0);
        p.ellipse(dinst.x, dinst.y, dinst.r, dinst.r);
        dinst.x += p.noise(dinst.xnoise) * 4 - 2;
        dinst.y += p.noise(dinst.ynoise) * 2 - 1;
        if (dinst.x < 0) {
          dinst.x = p.width;
        }
        if (dinst.x > p.width) {
          dinst.x = 0;
        }
        if (dinst.y < 0) {
          dinst.y = p.height;
        }
        if (dinst.y > p.height) {
          dinst.y = 0;
        }
        dinst.xnoise += .002;
        dinst.ynoise += .002;
        dinst.r += p.noise(dinst.rnoise) * 3 - 1.5;
        if (dinst.r < 50) {
          dinst.r = 50;
        }
        if (dinst.r > 250) {
          dinst.r = 250;
        }
        dinst.rnoise += .02;

      });

      // Dashes
      dashes.forEach(dinst => {
        p.stroke(56, 85, 97, 1);
        p.strokeWeight(6);
        p.line(dinst.x, dinst.y, dinst.x + dinst.r, dinst.y + dinst.r);
        dinst.x += p.noise(dinst.xnoise) * 4 - 2;
        if (dinst.x < 0) {
          dinst.x = p.width;
        }
        if (dinst.x > p.width) {
          dinst.x = 0;
        }
        dinst.xnoise += .002;
        dinst.rnoise += .02;

      });

      // p.calcWave();
      p.renderWave(p.calcWave(133), 0, [175, 50, 67]);
    }

    p.calcWave = (amplitude) => {
      // Increment theta (try different values for
      // 'angular velocity' here)
      theta += 0.02;

      // For every x value, calculate a y value with sine function
      let x = theta;
      let yValues = new Array(p.floor(w / xspacing));
      for (let i = 0; i < yValues.length; i++) {
        yValues[i] = p.sin(x) * amplitude;
        x += dx;
      }
      return yValues;
    }

    p.renderWave = (yValues, offset, colorHSB) => {
      p.noStroke();
      p.fill(56, 85, 97, 1);
      // A simple way to draw the wave with an ellipse at each location
      for (let x = 0; x < yValues.length; x++) {
        p.ellipse((x * xspacing) + offset, p.height / 2 + yValues[x], 5, 5);
      }




      p.strokeWeight(0);
      p.fill(colorHSB[0], colorHSB[1], colorHSB[1], .3);
      // We are going to draw a polygon out of the wave points
      p.beginShape();
      // Iterate over horizontal pixels
      for (let x = 0; x < yValues.length; x++) {
        // Set the vertex
        p.vertex((x * xspacing) + offset, p.height / 2 + yValues[x]);
        // Increment x dimension for noise
        // xoff += 0.05;
      }
      // increment y dimension for noise
      p.vertex(p.width, p.height);
      p.vertex(0, p.height);
      p.endShape(p.CLOSE);
    }

    p.ringsSet = () => {
      for (var i = 0; i < dotCount; i++) {
        rings[i] = {
          x: p.random(0, p.width),
          y: p.random(0, p.height),
          r: p.random(10, 100),
          xnoise: p.random(0, 10000),
          ynoise: p.random(0, 10000),
          rnoise: p.random(0, 10000)
        }
      }
    }

    p.dashesSet = () => {
      for (var i = 0; i < dotCount; i++) {
        dashes[i] = {
          x: p.random(0, p.width),
          y: p.random(0, p.height),
          r: p.random(10, 100),
          xnoise: p.random(0, 10000),
          ynoise: p.random(0, 10000),
          rnoise: p.random(0, 10000)
        }
      }
    }
  };

  toggleMenu() {
    this.setState(state => ({ menuOpen: !state.menuOpen }));
  }

  render() {
    return (
      <div className={styles.navigationWrapper}>
        <div className={styles.animation}>
          <div ref={wrapper => this.wrapper = wrapper}></div>
        </div>
        <div className={styles.navigationContent}>
          <div className={styles.navigationSection}>
            <h1>
              Software Consultancy and Development Services
            </h1>
            {/* <br />
            <Button label="We are hiring" to="/careers" glass cta /> */}
          </div>
        </div>
      </div>
    );
  }
}
