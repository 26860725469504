// extracted by mini-css-extract-plugin
export var app = "LayoutHome-module--app--297YH";
export var container = "LayoutHome-module--container--jM6+C";
export var content = "LayoutHome-module--content--Xs37V";
export var h1 = "LayoutHome-module--h1--9bU0z";
export var h2 = "LayoutHome-module--h2--ZA0x6";
export var h3 = "LayoutHome-module--h3--Kp4KT";
export var h4 = "LayoutHome-module--h4--A8DO+";
export var h5 = "LayoutHome-module--h5--bXXQO";
export var h6 = "LayoutHome-module--h6--Pl2yo";
export var hasHero = "LayoutHome-module--hasHero--Hj4-7";
export var header = "LayoutHome-module--header--RUGNJ";
export var p = "LayoutHome-module--p--0ShJ6";
export var pageTitle = "LayoutHome-module--pageTitle--qaNXs";